<template>
  <div>
    <Support v-if="$route.name.toLowerCase() != 'contact'" />
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-md-3 col-6">
            <h4 class="footer-heading">Company</h4>
            <ul class="footer-links">
              <li>
                <router-link to="/blogs"> Blogs </router-link>
              </li>
              <li>
                <router-link to="/about">About us</router-link>
              </li>
              <li>
                <a href="mailto:info@imperiumng.com" target="_blank"
                  >Contact us</a
                >
              </li>
              <li>
                <router-link to="/how-to"
                  >How to Buy and Sell on Imperium
                </router-link>
              </li>
              <li>
                <a href="https://vendor.imperiumng.com/#/signup" target="_blank"
                  >Become a vendor</a
                >
              </li>
              <li>
                <router-link to="/terms">Terms & conditions</router-link>
              </li>
              <li>
                <router-link to="/privacy">Privacy policy</router-link>
              </li>
            </ul>
          </div>
          <div class="col-md-3 col-6">
            <h4 class="footer-heading">Offerings</h4>
            <ul class="footer-links">
              <li>
                <router-link to="/energy-calculator"
                  >Energy calculator</router-link
                >
              </li>

              <li>
                <router-link to="/power-as-a-service"
                  >Power as a service</router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <h4 class="footer-heading">Get in touch</h4>
            <ul class="footer-links">
              <li>
                <router-link to="/faqs">FAQs</router-link>
              </li>
              <li>
                <a href="#">20, Marina, Lagos State</a>
              </li>
              <li>
                <router-link to="/">+234 (081) 6413 3937</router-link>
              </li>
              <li>
                <router-link to="/">+234 (081) 3547 3439</router-link>
              </li>
              <li>
                <a href="mailto:info@imperiumng.com">info@imperiumng.com</a>
              </li>
            </ul>
          </div>
          <div class="col-md-3">
            <h4 class="footer-heading">Follow us</h4>
            <ul class="footer-links">
              <li>
                <a href="#" class="pr-2">
                  <img src="/assets/images/svgs/twitter.svg" alt="" />
                </a>
                <a href="#" class="pr-2">
                  <img src="/assets/images/svgs/facebook.svg" alt="" />
                </a>
                <a href="#" class="pr-2">
                  <img src="/assets/images/svgs/envelope.svg" alt="" />
                </a>
                <a href="#" class="pr-2">
                  <img src="/assets/images/svgs/linkedin.svg" alt="" />
                </a>
              </li>
              <li class="pt-4">
                <div>
                  <p class="text-dark">Can't find what you're looking for?</p>
                  <a
                    tag="button"
                    href="mailto:info@imperiumng.com"
                    target="_blank"
                    class="btn btn-imp-secondary bg-primary"
                    style="color: white"
                  >
                    Send us an email</a
                  >
                </div>
              </li>
              <!-- <li class="pt-4">
                <div class="form-group positive-relative">
                  <input
                    type="text"
                    class="form-control"
                    name=""
                    id=""
                    aria-describedby="helpId"
                    placeholder="Email address"
                  />
                  <button class="btn btn-sm text-capitalize sub-button">
                    Subscribe
                  </button>
                </div>
              </li> -->
              <li>
                <a href="#">
                  <img src="/images/logo-white.svg" alt="" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="power">
          <p>
            Financial partner:
            <a href="https://sterling.ng" target="_blank">Sterling Bank</a>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>
<script>
import Support from "@/components/App/Support.vue";

export default {
  components: { Support },
};
</script>
<style lang="scss" scoped>
.footer {
  padding: 9.5rem 0 0;
}
.footer-links {
  padding: 0px;
  list-style: none;
  li {
    padding: 6px 0px;
    a {
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: black;
      opacity: 0.9;
    }
  }
}
.positive-relative {
  position: relative;
  input {
    border: 1px solid black;
    box-sizing: border-box;
    color: black;
    border-radius: 10px;
    background: transparent;
    &::placeholder {
      color: black;
    }
  }
}
.power {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: black;
  opacity: 0.9;
  padding-bottom: 10rem;
  p {
    margin: 0;
  }
}
</style>
