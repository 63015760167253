var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.$route.name.toLowerCase() != 'contact')?_c('Support'):_vm._e(),_c('footer',{staticClass:"footer"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-3 col-6"},[_c('h4',{staticClass:"footer-heading"},[_vm._v("Company")]),_c('ul',{staticClass:"footer-links"},[_c('li',[_c('router-link',{attrs:{"to":"/blogs"}},[_vm._v(" Blogs ")])],1),_c('li',[_c('router-link',{attrs:{"to":"/about"}},[_vm._v("About us")])],1),_vm._m(0),_c('li',[_c('router-link',{attrs:{"to":"/how-to"}},[_vm._v("How to Buy and Sell on Imperium ")])],1),_vm._m(1),_c('li',[_c('router-link',{attrs:{"to":"/terms"}},[_vm._v("Terms & conditions")])],1),_c('li',[_c('router-link',{attrs:{"to":"/privacy"}},[_vm._v("Privacy policy")])],1)])]),_c('div',{staticClass:"col-md-3 col-6"},[_c('h4',{staticClass:"footer-heading"},[_vm._v("Offerings")]),_c('ul',{staticClass:"footer-links"},[_c('li',[_c('router-link',{attrs:{"to":"/energy-calculator"}},[_vm._v("Energy calculator")])],1),_c('li',[_c('router-link',{attrs:{"to":"/power-as-a-service"}},[_vm._v("Power as a service")])],1)])]),_c('div',{staticClass:"col-md-3"},[_c('h4',{staticClass:"footer-heading"},[_vm._v("Get in touch")]),_c('ul',{staticClass:"footer-links"},[_c('li',[_c('router-link',{attrs:{"to":"/faqs"}},[_vm._v("FAQs")])],1),_vm._m(2),_c('li',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("+234 (081) 6413 3937")])],1),_c('li',[_c('router-link',{attrs:{"to":"/"}},[_vm._v("+234 (081) 3547 3439")])],1),_vm._m(3)])]),_vm._m(4)]),_vm._m(5)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"mailto:info@imperiumng.com","target":"_blank"}},[_vm._v("Contact us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://vendor.imperiumng.com/#/signup","target":"_blank"}},[_vm._v("Become a vendor")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("20, Marina, Lagos State")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"mailto:info@imperiumng.com"}},[_vm._v("info@imperiumng.com")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-3"},[_c('h4',{staticClass:"footer-heading"},[_vm._v("Follow us")]),_c('ul',{staticClass:"footer-links"},[_c('li',[_c('a',{staticClass:"pr-2",attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/assets/images/svgs/twitter.svg","alt":""}})]),_c('a',{staticClass:"pr-2",attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/assets/images/svgs/facebook.svg","alt":""}})]),_c('a',{staticClass:"pr-2",attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/assets/images/svgs/envelope.svg","alt":""}})]),_c('a',{staticClass:"pr-2",attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/assets/images/svgs/linkedin.svg","alt":""}})])]),_c('li',{staticClass:"pt-4"},[_c('div',[_c('p',{staticClass:"text-dark"},[_vm._v("Can't find what you're looking for?")]),_c('a',{staticClass:"btn btn-imp-secondary bg-primary",staticStyle:{"color":"white"},attrs:{"tag":"button","href":"mailto:info@imperiumng.com","target":"_blank"}},[_vm._v(" Send us an email")])])]),_c('li',[_c('a',{attrs:{"href":"#"}},[_c('img',{attrs:{"src":"/images/logo-white.svg","alt":""}})])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"power"},[_c('p',[_vm._v(" Financial partner: "),_c('a',{attrs:{"href":"https://sterling.ng","target":"_blank"}},[_vm._v("Sterling Bank")])])])
}]

export { render, staticRenderFns }